<template>
  <div class="game-container layout-border">
    <FindStroke
      :bgImg="bgImg"
      :gameIndex="gameIndex"
      :strokeImgList="strokeImgList"
      :showStar="true"
      :backgroundColor="blue"
    />
  </div>
</template>

<script>
import FindStroke from "@/components/Course/GamePage/FindStrokeGame";
export default {
  data() {
    return {
      bgImg: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-29-3/background-room.svg"),
      gameIndex:292,
      strokeImgList: [
         {
          // notFind: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-29-3/bird.svg"),
          find: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-29-3/bird-found.svg"),
        },
        {
          // notFind: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-29-3/cat.svg"),
          find: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-29-3/cat-found.svg"),
        },
       {
          // notFind: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-29-3/dog.svg"),
          find: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-29-3/dog-found.svg"),
        },
       
         {
          // notFind: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-29-3/fish.svg"),
          find: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-29-3/fish-found.svg"),
        },
      ],
    };
  },
  components: {
    FindStroke,
  },
};
</script>

<style lang="scss" scoped>
</style>