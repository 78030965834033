<template>
  <div class="game-container">
    <ClickImgToShowSentenceGame
      :bgImg="bgImg"
      :optionList="optionList"
      :maskImgList="maskImgList"
      @showCoeerctImg="showCoeerctImg"
      :lessonNO="29"
    />
  </div>
</template>

<script>
import ClickImgToShowSentenceGame from "@/components/Course/GamePage/ClickImgToShowSentenceGame";
export default {
  data() {
    return {
      bgImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-29-1/map.svg"),
      optionList: [
        {
          bgImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-29-1/place-1-pin.svg"),
          clickImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-29-1/place-1.svg"),
          id: 1,
          isShowCorrect: false,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-29-1/place-2-pin.svg"),
          clickImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-29-1/place-2.svg"),
          id: 2,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-29-1/place-3-pin.svg"),
          clickImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-29-1/place-3.svg"),
          id: 3,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-29-1/place-4-pin.svg"),
          clickImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-29-1/place-4.svg"),
          id: 4,
        },
        
      ],
      maskImgList: [
        {
          img: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-29-1/place-1-image.svg"),
          id: 1,
          centencePinyin: "Zhèr shì nǎr?",
          sentenceHanzi: "这儿是哪儿？",
        },
        {
          img: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-29-1/place-2-image.svg"),
          id: 2,
          centencePinyin: "Zhèr shì nǎr?",
          sentenceHanzi: "这儿是哪儿？",
        },
        {
          img: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-29-1/place-3-image.svg"),
          id: 3,
          centencePinyin: "Zhèr shì nǎr?",
          sentenceHanzi: "这儿是哪儿？",
        },
        {
          img: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-29-1/place-4-image.svg"),
          id: 4,
          centencePinyin: "Zhèr shì nǎr?",
          sentenceHanzi: "这儿是哪儿？",
        },
      ],
    };
  },
  components: {
    ClickImgToShowSentenceGame,
  },
  methods: {
    showCoeerctImg(index) {
      this.optionList[index - 1].isShowCorrect = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>











