<!--
 * @Author: your name
 * @Date: 2022-03-15 09:05:33
 * @LastEditTime: 2022-03-15 17:41:15
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \nihaokidsNew-front\src\views\Course\Level1\Chapter3\Lesson22\11_PracticePageOne_shuzhe.vue
-->
<template>
  <div class="game-container layout-border">
    <FindStroke
      :bgImg="bgImg"
      :gameIndex="gameIndex"
      :strokeImgList="strokeImgList"
      :showStar="true"
    />
  </div>
</template>

<script>
import FindStroke from "@/components/Course/GamePage/FindStrokeGame";
export default {
  data() {
    return {
      bgImg: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-29-1/background-room.svg"),
      gameIndex:29,
      strokeImgList: [
         {
          notFind: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-29-1/bird.svg"),
          find: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-29-1/bird-found.svg"),
        },
        {
          notFind: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-29-1/cat.svg"),
          find: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-29-1/cat-found.svg"),
        },
       {
          notFind: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-29-1/dog.svg"),
          find: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-29-1/dog-found.svg"),
        },
       
         {
          notFind: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-29-1/fish.svg"),
          find: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-29-1/fish-found.svg"),
        },
         
      ],
      // strockNotFoundImage: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-25/cat-pending.svg"),
      // strockFoundImage: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-25/cat-found.svg"),
      // title:{
      //   pinyin:"Zhè shì gǒu",
      //   hanzi:"这是狗"
      // }
    };
  },
  components: {
    FindStroke,
  },
};
</script>

<style lang="scss" scoped>
</style>