<template>
  <div class="syllavus-A-cotainer">
    <SyllabusPage :wordList="wordListData" :isPageA="true" />
  </div>
</template>

<script>
import SyllabusPage from "@/components/Course/CoursePage/SyllabusPage";

export default {
  name: "SyllabusPage-lesson-1-A",
  data() {
    return {
      wordListData: [
        {
          title: "我会认",
          isUse: false,
          content: [
            {
              pinyin: "zài",
              hanzi: "在",
              symbol: "",
              group: 0,
              index: 0,
              symbolType: 0,
            },
            {
              pinyin: "",
              hanzi: "",
              symbol: "、",
              group: 0,
              index: 1,
              symbolType: 1,
            },
            {
              pinyin: "zhèr",
              hanzi: "这儿",
              symbol: "",
              group: 0,
              index: 2,
              symbolType: 0,
            },
            {
              pinyin: "",
              hanzi: "",
              symbol: "、",
              group: 0,
              index: 3,
              symbolType: 1,
            },
            {
              pinyin: "nàr",
              hanzi: "那儿",
              symbol: "",
              group: 0,
              index: 4,
              symbolType: 0,
            },
            {
              pinyin: "",
              hanzi: "",
              symbol: "、",
              group: 0,
              index: 5,
              symbolType: 1,
            },
            {
              pinyin: "nǎr",
              hanzi: "哪儿",
              symbol: "",
              group: 0,
              index: 6,
              symbolType: 0,
            },

            // symbolType: 0word 1、 2： 3 “” 4！ 5…… 6。
          ],
        },
        {
          title: "我会用",
          isUse: true,
          contentList: [
            [
              // {
              //   pinyin: "nǐ",
              //   hanzi: "你",
              //   symbol: "",
              //   id: 1,
              //   symbolType: 0,
              // },

              // symbolType: 0word 1、 2： 3 “ 4！ 5…… 6。7”

              {
                pinyin: "jùxíng",
                hanzi: "句型",
                symbol: "",
                group: 1,
                line: 1,
                index: 0,
                symbolType: 0,
              },
              {
                pinyin: "",
                hanzi: "",
                symbol: "：",
                group: 1,
                line: 1,
                index: 1,
                symbolType: 2,
              },
              {
                pinyin: "",
                hanzi: "",
                symbol: "“",
                group: 1,
                line: 1,
                index: 2,
                symbolType: 3,
              },
              {
                pinyin: "",
                hanzi: "",
                symbol: "......",
                group: 1,
                line: 1,
                index: 3,
                symbolType: 0,
              },
              {
                pinyin: "zài",
                hanzi: "在",
                symbol: "",
                group: 1,
                line: 1,
                index: 4,
                symbolType: 0,
              },
              {
                pinyin: "nǎr",
                hanzi: "哪儿",
                symbol: "",
                group: 1,
                line: 1,
                index: 5,
                symbolType: 0,
              },
              {
                pinyin: "",
                hanzi: "",
                symbol: "?",
                group: 1,
                line: 1,
                index: 6,
                symbolType: 2,
              },
              {
                pinyin: "",
                hanzi: "",
                symbol: "”",
                group: 1,
                line: 1,
                index: 7,
                symbolType: 7,
              },
              {
                pinyin: "xún wèn",
                hanzi: "询问",
                symbol: "",
                group: 1,
                line: 1,
                index: 8,
                symbolType: 0,
              },
              {
                pinyin: "wèi zhì",
                hanzi: "位置",
                symbol: "",
                group: 1,
                line: 1,
                index: 9,
                symbolType: 0,
              },
            ],
            [
              {
                pinyin: "rú",
                hanzi: "如",
                symbol: "",
                group: 1,
                line: 2,
                index: 0,
                symbolType: 0,
              },
              {
                pinyin: "",
                hanzi: "",
                symbol: "：",
                group: 1,
                line: 2,
                index: 1,
                symbolType: 2,
              },
              {
                pinyin: "gēge ",
                hanzi: "哥哥",
                symbol: "",
                group: 1,
                line: 2,
                index: 2,
                symbolType: 0,
              },
              {
                pinyin: "zài",
                hanzi: "在",
                symbol: "",
                group: 1,
                line: 2,
                index: 3,
                symbolType: 0,
              },
              {
                pinyin: "nǎr",
                hanzi: "哪儿",
                symbol: "",
                group: 1,
                line: 2,
                index: 4,
                symbolType: 0,
              },
            
              {
                pinyin: "",
                name: "",
                symbol: "?",
                group: 1,
                line: 2,
                index: 5,
                symbolType: 2,
              },
            ],
          ],
        },
        {
          title: "我会说",
          isUse: false,
          content: [
            // {
            //   pinyin: "nǐ",
            //   hanzi: "你",
            //   symbol: "",
            //   id: 1,
            //   symbolType: 0,
            // },

            {
              pinyin: "yòng",
              hanzi: "用",
              symbol: "",
              group: 2,
              index: 0,
              symbolType: 0,
            },
            {
              pinyin: "Zhōngwén",
              hanzi: "中文",
              symbol: "",
              group: 2,
              index: 1,
              symbolType: 0,
            },

            {
              pinyin: "jiǎndān de",
              hanzi: "简单地",
              symbol: "",
              group: 2,
              index: 2,
              symbolType: 0,
            },
            {
              pinyin: " xún wèn",
              hanzi: "询问",
              symbol: "",
              group: 2,
              index: 3,
              symbolType: 0,
            },
             {
              pinyin: "hé",
              hanzi: "和",
              symbol: "",
              group: 2,
              index: 4,
              symbolType: 0,
            },
            {
              pinyin: "biǎo dá",
              hanzi: "表达",
              symbol: "",
              group: 2,
              index: 5,
              symbolType: 0,
            },
             {
              pinyin: "wèi zhì",
              hanzi: "位置",
              symbol: "",
              group: 2,
              index: 6,
              symbolType: 0,
            },
          ],
        },
      ],
    };
  },
  components: {
    SyllabusPage,
  },
};
</script>

<style lang="scss" scoped>
.syllavus-A-cotainer {
  width: 100%;
  height: 100%;
}
</style>