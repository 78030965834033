<template>
  <!-- layout-container lesson 9 -->
  <div class="lesson29-container">
    <div class="display-page" v-if="pageType === 0">
      <template
        v-for="(item, key, index) in componentsList"
        class="display-page"
      >
        <keep-alive :key="index + 'keep'">
          <transition name="slide-fade" :key="index">
            <component :key="index" :is="item" v-if="index + 1 === pageNum">
            </component>
          </transition>
        </keep-alive>
      </template>
    </div>
    <div class="display-page" v-if="pageType === 1">
      <SyllabusPageA />
    </div>
    <div class="display-page test" v-if="pageType === 2">
      <WhiteBoard />
    </div>
    <div class="whiteBoard-box" v-if="globalWhiteB">
      <!-- 白板部分 -->
      <WhiteBoard :canvasHeight="cvHeight" :canvasWidth="cvWidth" />
    </div>
  </div>
</template>
<script>
import { lessonMixin } from "@/mixins/lessonDefault.js";
import SyllabusPageA from "./3_SyllabusPage_A.vue";

import WhiteBoard from "@/components/Course/WhiteBoard/WhiteBoard.vue";

import LessonPage from "./1_Lesson.vue";
import RankMapPageOne from "./2_RankMapPageOne.vue";
import TargetPage from "./3_Target.vue";
import IntroductionPage from "./4_Introduction.vue";
import StoryPageOne from "./5_StoryPageOne.vue";
import RecognitionPageOne from "./6_RecognitionPageOne.vue";
import PracticePageOne from "./7_PracticePageOne.vue";
import RecognitionPageTwo from "./8_RecognitionPageTwo.vue";
import StoryPageTwo from "./9_StoryPageTwo.vue";
import RecognitionPageThree from "./10_RecognitionPageThree.vue";

import PracticePageTwo from "./11_PracticePageTwo.vue";
import RecognitionPageFour from "./12_RecognitionPageFour.vue";
import PracticePageThree from "./13_PracticePageThree.vue";
import StoryPageThree from "./14_StoryPageThree.vue";
import PracticePageFour from "./15_PracticePageFour.vue";
import PracticePageFive from "./16_PracticePageFive.vue";

import SingPage from "./17_SingPage.vue";
import WordsCardPage from "./18_WordsCardPage.vue";
import SummaryPage from "./19_SummaryPage.vue";
import RankMapPageTwo from "./20_RankMapPageTwo.vue";
import ByePage from "./21_ByePage.vue";
export default {
  name: "Lesson29",
  mixins: [lessonMixin],
  components: {
    SyllabusPageA,
    WhiteBoard,
    LessonPage,
    RankMapPageOne,
    TargetPage,
    IntroductionPage,
    StoryPageOne,
    RecognitionPageOne,
    PracticePageOne,
    RecognitionPageTwo,
    StoryPageTwo,
    RecognitionPageThree,
    RecognitionPageFour,
    PracticePageTwo,
    StoryPageThree,
    PracticePageThree,
    PracticePageFour,
    PracticePageFive,
    SingPage,
    WordsCardPage,
    SummaryPage,
    RankMapPageTwo,
    ByePage,
  },
  data() {
    return {
      componentsList: {
        LessonPage,
        RankMapPageOne,
        TargetPage,
        IntroductionPage,
        StoryPageOne,
        RecognitionPageOne,
        PracticePageOne,
        RecognitionPageTwo,
        StoryPageTwo,
        RecognitionPageThree,
        PracticePageTwo,
        RecognitionPageFour,
        PracticePageThree,
        StoryPageThree,
        PracticePageFour,
        PracticePageFive,
        SingPage,
        WordsCardPage,
        SummaryPage,
        RankMapPageTwo,
        ByePage,
      },
    };
  },
};
</script>
>

<style lang="scss" scoped>
.lesson29-container {
  width: 100%;
  height: 100%;
  .whiteBoard-box {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    line-height: 1;
  }

  // .page-item {
  //   width: 100%;
  //   height: 100%;

  .display-page {
    width: 100%;
    height: 100%;
    // background-color: #fff;
    border-radius: 56px;
  }
  .test {
    background-color: #fff;
  }

  // }
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
