<template>
  <div class="target-container">
    <StartStop :position="positionStr" :pageInfo="pageInfoObj" :chapterNo="4" />
  </div>
</template>

<script>
import StartStop from "@/components/Course/CoursePage/StartStop";

export default {
  data() {
    return {
      positionStr: "begin",
      pageInfoObj: {
        pinyin: "Gēge zài nǎr? (shàng)",
        hanzi: "哥哥在哪儿？ (上)",
        english: "Where's my brother? (I)",
        sound: require("@/assets/audio/L1/1-Lesson/lesson-29-title.mp3")
      },
    };
  },
  components: {
    StartStop,
  },
};
</script>

<style lang="scss" scoped>
.target-container {
  width: 100%;
  height: 100%;
}
</style>
