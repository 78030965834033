<template>
  <div class="wordscard-container layout-border">
    <WordCards
      :wordCardList="wordCardList"
      @changeDisplay="handleChangeDisplay"
      :lessonNum="11"
    />
  </div>
</template>
<script>
import WordCards from "@/components/Course/CoursePage/WordCards";

export default {
  components: {
    WordCards,
  },
  data() {
    return {
      wordCardList: [
        {
          bgColor: "redColor",
          order: "one",
          isShowImg: "true",
          currentNum: 1,
          pinyin: "zài",
          hanzi: "在",
          english: "in, at",
          image: require(`@/assets/img/12-Vocabulary/lesson-29-zai-bubble.svg`),
        },
        {
          bgColor: "redColor",
          order: "three",
          isShowImg: "true",
          currentNum: 3,
          pinyin: "zhèr",
          hanzi: "这儿",
          english: "here",
          image: require(`@/assets/img/12-Vocabulary/lesson-29-zher-bubble.svg`),
        },
        {
          bgColor: "redColor",
          order: "five",
          isShowImg: "true",
          currentNum: 4,
          pinyin: "nàr",
          hanzi: "那儿",
          english: "there",
          image: require(`@/assets/img/12-Vocabulary/lesson-29-nar4-bubble.svg`),
        },
        {
          bgColor: "redColor",
          order: "two",
          isShowImg: "true",
          currentNum: 2,
          pinyin: "nǎr",
          hanzi: "哪儿",
          english: "where",
          image: require(`@/assets/img/12-Vocabulary/lesson-29-nar3-bubble.svg`),
        },
        {
          bgColor: "redColor",
          order: "four",
          isShowImg: "true",
          currentNum: 4,
          pinyin: "... zài nǎr ?",
          hanzi: "……在哪儿？",
          english: "Where are/is ... ?",
          image: require(`@/assets/img/12-Vocabulary/lesson-29-zai-nar.svg`),
          longtext:true,
          blue: true
        },
       
      ],
    };
  },
  methods: {
    handleChangeDisplay(index, isShowImg) {
      this.wordCardList[index].isShowImg = isShowImg;
    },
  },
};
</script>
<style lang="scss" scoped>
.wordscard-container {
  width: calc(100% - 20px);
  height: calc(100% - 40px);
  &.chapter-wordcard {
    background-color: #fff;
  }
}
</style>
